<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h4 class="mb-2 font-weight-bolder">{{ office_name }}</h4>

        <div class="font-small-2">RMP USD/Kg</div>
        <h5 class="mb-1">{{ rmp_usd_kg }}</h5>

        <div class="font-small-2">WFE Kg</div>
        <h5 class="mb-1">{{ wfe_kg }}</h5>
      </div>
      <b-form-checkbox
        checked="true"
        class="custom-control-primary"
        name="check-button"
        switch
        @change="(checked) => { this.$emit('switchChange', checked, office_index); }"
      />
      <!--
                <b-avatar variant="light-secondary" size="45">
                  <feather-icon size="21" icon="TrendingUpIcon" />
                </b-avatar>
                -->
    </b-card-body>
  </b-card>
</template>

<script>


import
{
  BCard,
  BCardBody,
  BFormCheckbox
} from "bootstrap-vue";



export default {

  directives: {
  },

   props: {
    office_name: {
      type: String,
    },
    office_index: {
      type: Number,
    },


    rmp_usd_kg: {
      type: Number,
    },

    wfe_kg: {
      type: Number,
    }



  },

  data()
  {


    return {



    };
  },


  components: {
    BCard,
    BCardBody,
    BFormCheckbox

  },

  created()
  {




  },


  methods: {




  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

